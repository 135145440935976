var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"main governments"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"main__inner"},[_c('section',{staticClass:"section"},[_c('h2',[_vm._v("Органы власти")]),_c('form',{staticClass:"filter__form",attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.submitFilterForm.apply(null, arguments)}}},[_c('div',{staticClass:"row search"},[_c('div',{staticClass:"col-12 search__input"},[_c('label',{staticClass:"search__label",attrs:{"for":"docs_search"}},[_vm._v(" Название органа власти для поиска ")]),_c('div',{staticClass:"search__input__wrap"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.title.value),expression:"form.title.value"}],attrs:{"id":"docs_search","type":"text","maxlength":"255"},domProps:{"value":(_vm.form.title.value)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.form.title, "value", $event.target.value)},_vm.inputChange]}}),(_vm.form.title.value)?_c('button',{attrs:{"type":"button"},on:{"click":_vm.resetSearchInput}},[_c('CloseIcon')],1):_vm._e(),(_vm.form.title.value)?_c('div',{staticClass:"search__result-dropdown"},[(_vm.loading)?_c('LoadingIndicator'):(_vm.searchResults && _vm.searchResults.length)?_c('ul',_vm._l((_vm.searchResults),function(item,index){return _c('li',{key:index,staticClass:"search__result-item"},[(_vm.isInstitution(item))?_c('router-link',{attrs:{"to":{
                          name: 'localOrganization',
                          params: { id: item.id },
                        }}},[_vm._v("\n                        "+_vm._s(item.title)+"\n                      ")]):_c('a',{attrs:{"href":_vm._f("url")(item.url),"target":"_blank"}},[_vm._v("\n                        "+_vm._s(item.title)+"\n                      ")])],1)}),0):_c('p',[_vm._v("Ничего не найдено")])],1):_vm._e()])])])])]),_c('section',{staticClass:"section section__governments"},[_c('h2',{staticClass:"governments-title"},[_vm._v("Муниципальное образование")]),_c('div',{staticClass:"governments-list governments-list--blue"},[_c('router-link',{staticClass:"governments-list__main",attrs:{"to":{ name: 'mayor' }}},[_vm._v("Глава города")]),(_vm.mayorDepartments && _vm.mayorDepartments.length)?_c('div',{staticClass:"governments-list__links"},_vm._l((_vm.mayorDepartments),function(item,index){return _c('div',{key:index},[(_vm.isInstitution(item))?_c('router-link',{staticClass:"governments-list__link",attrs:{"to":{
                  name: 'localOrganization',
                  params: { id: item.id },
                }}},[_vm._v("\n                "+_vm._s(item.title)+"\n              ")]):_c('a',{staticClass:"governments-list__link",attrs:{"href":_vm._f("url")(item.url),"target":"_blank"}},[_vm._v("\n                "+_vm._s(item.title)+"\n              ")])],1)}),0):_vm._e()],1),_c('div',{staticClass:"governments-list"},[_c('a',{staticClass:"governments-list__main",attrs:{"href":"#"}},[_vm._v("Аппарат администрации")]),(_vm.government.apparatus && _vm.government.apparatus.length)?_c('div',{staticClass:"governments-list__links"},_vm._l((_vm.government.apparatus),function(item,index){return _c('div',{key:index},[(_vm.isInstitution(item))?_c('router-link',{staticClass:"governments-list__link",attrs:{"to":{
                  name: 'localOrganization',
                  params: { id: item.id },
                }}},[_vm._v("\n                "+_vm._s(item.title)+"\n              ")]):_c('a',{staticClass:"governments-list__link",attrs:{"href":_vm._f("url")(item.url),"target":"_blank"}},[_vm._v("\n                "+_vm._s(item.title)+"\n              ")])],1)}),0):_vm._e()]),_vm._m(0),_vm._m(1),(_vm.government.categories && _vm.government.categories.length)?_c('h2',{staticClass:"governments-title"},[_vm._v("\n          Комплексы городского управления города\n        ")]):_vm._e(),_vm._l((_vm.government.categories),function(item,index){return _c('div',{key:index,staticClass:"governments-list"},[_c('a',{staticClass:"governments-list__main",attrs:{"href":"#"}},[_vm._v(_vm._s(item.title))]),(item.departments && item.departments.length)?_c('div',{staticClass:"governments-list__links"},_vm._l((item.departments),function(dep,i){return _c('div',{key:i},[(_vm.isInstitution(dep))?_c('router-link',{staticClass:"governments-list__link",attrs:{"to":{
                  name: 'localOrganization',
                  params: { id: dep.id },
                }}},[_vm._v("\n                "+_vm._s(dep.title)+"\n              ")]):_c('a',{staticClass:"governments-list__link",attrs:{"href":_vm._f("url")(dep.url),"target":"_blank"}},[_vm._v("\n                "+_vm._s(dep.title)+"\n              ")])],1)}),0):_vm._e()])})],2)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"governments-list governments-list--blue"},[_c('a',{staticClass:"governments-list__main",attrs:{"href":"https://gs.mkala.ru","target":"_blank"}},[_vm._v("\n            Городское собрание\n          ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"governments-list governments-list--blue"},[_c('a',{staticClass:"governments-list__main",attrs:{"href":"https://ksp.mkala.ru","target":"_blank"}},[_vm._v("\n            Контрольно-счетная палата\n          ")])])}]

export { render, staticRenderFns }