<template>
  <main class="main governments">
    <div class="container">
      <div class="main__inner">
        <section class="section">
          <h2>Органы власти</h2>
          <form @submit.prevent="submitFilterForm" class="filter__form" autocomplete="off">
            <div class="row search">
              <div class="col-12 search__input">
                <label for="docs_search" class="search__label"> Название органа власти для поиска </label>
                <div class="search__input__wrap">
                  <input
                    @input="inputChange"
                    v-model="form.title.value"
                    id="docs_search"
                    type="text"
                    maxlength="255"
                  />
                  <button v-if="form.title.value" type="button" @click="resetSearchInput">
                    <CloseIcon />
                  </button>
                  <div v-if="form.title.value" class="search__result-dropdown">
                    <LoadingIndicator v-if="loading" />
                    <ul v-else-if="searchResults && searchResults.length">
                      <li v-for="(item, index) in searchResults" :key="index" class="search__result-item">
                        <router-link
                          :to="{
                            name: 'localOrganization',
                            params: { id: item.id },
                          }"
                          v-if="isInstitution(item)"
                        >
                          {{ item.title }}
                        </router-link>
                        <a v-else :href="item.url | url" target="_blank">
                          {{ item.title }}
                        </a>
                      </li>
                    </ul>
                    <p v-else>Ничего не найдено</p>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
        <section class="section section__governments">
          <h2 class="governments-title">Муниципальное образование</h2>
          <div class="governments-list governments-list--blue">
            <router-link :to="{ name: 'mayor' }" class="governments-list__main">Глава города</router-link>
            <div v-if="mayorDepartments && mayorDepartments.length" class="governments-list__links">
              <div v-for="(item, index) in mayorDepartments" :key="index">
                <router-link
                  :to="{
                    name: 'localOrganization',
                    params: { id: item.id },
                  }"
                  v-if="isInstitution(item)"
                  class="governments-list__link"
                >
                  {{ item.title }}
                </router-link>
                <a v-else :href="item.url | url" class="governments-list__link" target="_blank">
                  {{ item.title }}
                </a>
              </div>
            </div>
          </div>
          <div class="governments-list">
            <a href="#" class="governments-list__main">Аппарат администрации</a>
            <div v-if="government.apparatus && government.apparatus.length" class="governments-list__links">
              <div v-for="(item, index) in government.apparatus" :key="index">
                <router-link
                  :to="{
                    name: 'localOrganization',
                    params: { id: item.id },
                  }"
                  class="governments-list__link"
                  v-if="isInstitution(item)"
                >
                  {{ item.title }}
                </router-link>

                <a v-else :href="item.url | url" class="governments-list__link" target="_blank">
                  {{ item.title }}
                </a>
              </div>
            </div>
          </div>
          <div class="governments-list governments-list--blue">
            <a href="https://gs.mkala.ru" class="governments-list__main" target="_blank">
              Городское собрание
            </a>
          </div>
          <div class="governments-list governments-list--blue">
            <a href="https://ksp.mkala.ru" class="governments-list__main" target="_blank">
              Контрольно-счетная палата
            </a>
          </div>
          <h2 v-if="government.categories && government.categories.length" class="governments-title">
            Комплексы городского управления города
          </h2>

          <div v-for="(item, index) in government.categories" :key="index" class="governments-list">
            <a href="#" class="governments-list__main">{{ item.title }}</a>

            <div v-if="item.departments && item.departments.length" class="governments-list__links">
              <div v-for="(dep, i) in item.departments" :key="i">
                <router-link
                  :to="{
                    name: 'localOrganization',
                    params: { id: dep.id },
                  }"
                  class="governments-list__link"
                  v-if="isInstitution(dep)"
                >
                  {{ dep.title }}
                </router-link>

                <a v-else :href="dep.url | url" class="governments-list__link" target="_blank">
                  {{ dep.title }}
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import CloseIcon from "@/components/svg/CloseIcon.vue";

export default {
  name: "LocalGovernments",
  async asyncData({ store }) {
    await store.dispatch("GET_GOVERNMENT_PAGE");
  },
  data() {
    return {
      loading: false,
      form: {
        title: {
          value: null,
          message: null,
        },
        category_id: {
          value: null,
          message: null,
        },
      },
    };
  },
  computed: {
    government() {
      return this.$store.state.government_page;
    },
    mayorDepartments() {
      let depArr = [];
      if (this.government && this.government.department_mayor && this.government.department_mayor.childs) {
        this.government.department_mayor.childs.forEach((item) => {
          if (item.department) {
            depArr.push(item.department);
          }
        });
      }
      return depArr;
    },
    allDepartments() {
      let depts = [];
      if (this.government.categories && this.government.categories.length) {
        this.government.categories.forEach((item) => {
          if (item.departments && item.departments.length) {
            depts.push(...item.departments);
          }
        });
      }
      return depts;
    },
    /**
     * Поиск
     */
    searchResults() {
      if (this.form.title.value) {
        return this.allDepartments.filter((item) => {
          return item.title.toLowerCase().indexOf(this.form.title.value.toLowerCase()) !== -1;
        });
      }
      return [];
    },
  },
  methods: {
    isInstitution(item) {
      return (item.type && item.type.code === this.$store.state.type.CODE_INSTITUTIONS) || !item.url;
    },
    inputChange() {
      clearTimeout(this.timeout);
      this.loading = true;
      this.timeout = setTimeout(() => {
        this.submitFilterForm();
      }, 500);
    },
    /**
     * Фильтр органов власти
     * @returns {Promise<void>}
     */
    async submitFilterForm() {
      this.loading = true;
      let fd = {
        title: this.form.title.value,
      };
      await this.$store.dispatch("GET_GOVERNMENT_PAGE", fd).finally(() => {
        this.loading = false;
      });
    },
    /**
     * Очищение строки поиска
     */
    resetSearchInput() {
      this.form.title.value = null;
      this.submitFilterForm();
    },
  },
  components: {
    LoadingIndicator,
    CloseIcon,
  },
  metaInfo() {
    return this.$seo(
      "common",
      "Официальный сайт администрации города Махачкалы",
      "",
      "",
      "Официальный сайт администрации города Махачкалы",
      "",
      ""
    );
  },
};
</script>

<style lang="stylus">
@import '~flatpickr/dist/flatpickr.min.css'

.section.section__governments {
  padding-top: 0;
}

.governments-title {
  margin 48px 0
  font-weight: bold;
  font-size: 1.375em;
  line-height: 28px;
  color: var(--color_dark);
  +below(1200px) {
    margin 32px 0 24px
  }
  +below(768px) {
    font-size: 1.125em
    line-height: 24px;
    margin 24px 0 16px
  }
  +below(380px) {
    margin 16px 0 0
    font-size: 1em
    line-height: 20px;
  }
}

.governments-list {
  width 100%
  display grid
  grid-template-columns 360px auto
  column-gap 24px
  align-items flex-start
  padding 16px 0
  +below(990px) {
    grid-template-columns 250px auto
  }
  +below(768px) {
    grid-template-columns 150px auto
  }
  +below(620px) {
    grid-template-columns 1fr
    row-gap 16px
  }
  +below(380px) {
    row-gap 12px
    font-size: 0.875em
    line-height: 20px;
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--color_gray_divider);
  }

  &__links {
    display grid
    grid-gap 16px
    +below(380px) {
      grid-gap 8px
    }
  }

  &__link {
    font-weight: normal;
    font-size: 1em;
    line-height: 24px;
    color: var(--color_dark);
    +below(768px) {
      font-size: 0.875em
      line-height: 20px;
    }
    //+below(380px) {
    //  font-size: 0.75em
    //  line-height: 16px;
    //}
  }

  &--blue &__main {
    color: var(--color_blue_dark);
  }

  &__main {
    font-weight: 500;
    font-size: 1.125em;
    line-height: 24px;
    color: var(--color_dark);
    word-break break-word
    +below(768px) {
      font-size: 1em
      line-height: 20px;
    }
  }
}
</style>
